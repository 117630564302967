import { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import DataGrid, {
  Column,
  Lookup,
  Editing,
  RangeRule,
  Button,
  RequiredRule,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";

import moment from "moment";
import { OidcIdentityContext } from "@rsmus/react-auth";

import { ScheduleStatus, ServiceBusEnum, SyncTypeEnum, TimeUnit } from "../../api-client-nswag/taxportal-client";
import { dataGridConfig } from "../../constants";
import { reducer } from "./reducer/reducer";
import { useRecoilValue } from "recoil";
import { fetchData, applyChanges, updateChanges, setEditRowKey } from "./reducer/actions";

import { convertUTCTimeToLocal, selectValues } from "../../util/Utils";
import { IChangeProps } from "devextreme-react/tree-list";

import useSnackbar from "../../hooks/useSnackbar";
import { ViewExecutionLogs } from "../../cui/components/ViewExecutionLogs/ViewExecutionLogs";
import ModalUnsavedWarning from "../../cui/components/Modal/ModalUnsavedWarning";
import AppNameToggle, { getAppName } from "../../cui/commonComponents/AppNameToggle";
import { CellDateTimePicker } from "../../cui/components/CellDatePicker/CellDateTimePicker";

import { constants, EditModeType, InitialScheduleStateProps, SelectOption, TAX_PORTAL } from "./ScheduleFiles.types";

type DatagridRefType = DataGrid;

const {
  APP,
  SCHEDULE_NAME,
  TIME,
  FREQUENCY,
  START_EXECUTION_DATE,
  LAST_EXECUTION,
  LAST_EXECUTION_DATE,
  NEXT_EXECUTION_DATE,
  LOG_DETAILS,
  GRID_DATE_TIME_FORMAT,
} = constants;

const initialScheduleState: InitialScheduleStateProps = {
  data: [],
  changes: [],
  editRowKey: null,
  editRow: null,
  isLoading: false,
  userName: "",
};

const values = [TimeUnit.NULL, TimeUnit.SECONDS, TimeUnit.MINUTES, TimeUnit.HOURS];
const status = [ScheduleStatus.SCHEDULED, ScheduleStatus.STOPPED];

const statusOptions: SelectOption[] = selectValues(status);
const frequencyOptions: SelectOption[] = selectValues(values);

export const ScheduleFiles = () => {
  const scheduleFilesGridRef = useRef<DatagridRefType>(null);
  const { user } = useContext(OidcIdentityContext);
  const userName = user?.profile.preferred_username!;
  const { showSnackbar } = useSnackbar();
  const appName = useRecoilValue(getAppName);
  const minSelectableDate = new Date();
  minSelectableDate.setHours(0, 0, 0, 0);

  const [scheduleState, dispatch] = useReducer(reducer, initialScheduleState);
  const [selectedTypeNumber, setSelectedTypeNumber] = useState<number>();
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(false);
  const [saveMode, setSaveMode] = useState<EditModeType>({
    isEditing: false,
    isSaving: false,
  });
  const handleRefresh = () => fetchData(dispatch, userName, showSnackbar);

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: handleRefresh,
  };

  const forceDateFormat = (date: string) => {
    const val = moment(date);
    return val.isValid() ? val.utc().format(GRID_DATE_TIME_FORMAT).toString() : null;
  };

  const cellRenderForDate = useCallback((date: string) => {
    if (!date) return "";
    const value = typeof date !== "string" ? forceDateFormat(date) : date;
    if (!value) return "";
    return <div>{convertUTCTimeToLocal(value)}</div>;
  }, []);

  const onEditRowKeyChange = useCallback((editRowKey: number) => {
    setEditRowKey(dispatch, editRowKey);
    setSaveMode({
      isEditing: true,
      isSaving: false,
    });
  }, []);

  const onChangesChange = useCallback((changes: IChangeProps[]) => {
    updateChanges(dispatch, changes);
  }, []);

  const onSaving = useCallback(
    (e: any, userName: string) => {
      e.cancel = true;
      e.promise = applyChanges(dispatch, e.changes[0], scheduleState, userName);
      setSaveMode({
        isEditing: false,
        isSaving: true,
      });
    },
    [scheduleState]
  );

  const handleOpenModal = (e: any) => {
    let syncType = e?.row.data.jobType;
    if(syncType === "EXTERNAL_FOLDER_CREATION"){
      syncType = SyncTypeEnum.CREATE_FOLDER_EXTERNAL 
    }
    setSelectedTypeNumber(SyncTypeEnum[syncType as keyof typeof SyncTypeEnum]);
    setIsDisplayModal(true);
  };
  const handleCloseModal = () => {
    setSelectedTypeNumber(0);
    setIsDisplayModal(false);
  };

  const onEditCanceled = () => {
    setSaveMode({
      isEditing: false,
      isSaving: true,
    });
  };

  useEffect(() => {
    fetchData(dispatch, userName, showSnackbar);
  }, []);

  const cellRender = (e: any) => {
    if (e.data.appName === null) {
      return appName;
    }
    return e.data.appName;
  };

  return (
    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
      <div style={{ marginLeft: "3px", marginTop: "40px", display: "flex" }}>
        <h2>Schedule Executions</h2>
        <AppNameToggle />
      </div>
      <DataGrid
        id="gridContainer"
        keyExpr="id"
        ref={scheduleFilesGridRef}
        dataSource={scheduleState?.data?.filter((e: any) => {
          return e.appName === appName || e.appName === null;
        })}
        {...dataGridConfig}
        noDataText={scheduleState.isLoading ? "Loading..." : "No data"}
        repaintChangesOnly
        onSaving={(e) => onSaving(e, userName)}
        onEditCanceled={onEditCanceled}
      >
        <Editing
          mode="row"
          allowUpdating
          useIcons
          changes={scheduleState.changes}
          onChangesChange={onChangesChange}
          editRowKey={scheduleState.editRowKey}
          onEditRowKeyChange={onEditRowKeyChange}
        />
        <Toolbar>
          <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
        </Toolbar>
        <Column caption={APP} dataField="appName" cellRender={cellRender} width={40} allowEditing={false} />
        <Column dataField="id" allowEditing={false} width={40} />
        <Column caption={SCHEDULE_NAME} width={230} dataField="displayName" allowEditing={false} />
        <Column dataField="frequencyValue" width={50} caption={TIME}>
          <RequiredRule />
          <RangeRule min={1} message="Only values greater than 0 are allowed." />
        </Column>
        <Column caption={FREQUENCY} width={80} dataField="frequencyType" dataType="number">
          <RequiredRule />
          <Lookup dataSource={frequencyOptions} valueExpr="ID" displayExpr="Name" />
        </Column>
        {appName === TAX_PORTAL && (
          <Column
            dataField="startExecution"
            width={190}
            caption={START_EXECUTION_DATE}
            dataType="date"
            cellRender={(e) => cellRenderForDate(e.data.startExecution)}
            editCellRender={({ value, setValue }) => (
              <CellDateTimePicker value={value} setValue={setValue} showTimeOptions minDate={minSelectableDate} />
            )}
          />
        )}
        <Column dataField="status" width={100}>
          <Lookup dataSource={statusOptions} valueExpr="ID" displayExpr="Name" />
        </Column>
        <Column caption={LAST_EXECUTION} dataField="lastExecutionStatus" width={110} allowEditing={false} />
        <Column
          cellRender={(e) => cellRenderForDate(e.data.lastExecutionDateTime)}
          dataField="lastExecutionDateTime"
          width={200}
          caption={LAST_EXECUTION_DATE}
          allowEditing={false}
        >
          NULL
        </Column>
        <Column
          cellRender={(e) => cellRenderForDate(e.data.nextExecutionDateTime)}
          dataField="nextExecutionDateTime"
          width={200}
          caption={NEXT_EXECUTION_DATE}
          allowEditing={false}
        />
        <Column dataField="actions" type="buttons" width={80} caption={LOG_DETAILS}>
          <Button icon="file" onClick={handleOpenModal} />
        </Column>
        <Column type="buttons" width={80}>
          <Button name="edit" />
        </Column>
      </DataGrid>
      <ViewExecutionLogs
        optionsDropdown={scheduleState.data!}
        optionSelected={selectedTypeNumber!}
        handleClose={handleCloseModal}
        isDisplayModal={isDisplayModal}
      />
      <ModalUnsavedWarning when={saveMode.isEditing && !saveMode.isSaving} />
    </div>
  );
};
