import { useCallback, useEffect, useState } from "react";
import { FormControl, MenuItem, Pagination, Select, SelectChangeEvent } from "@mui/material";
import { Modal } from "../Modal/Modal";
import {
  DataGrid,
  Column,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  SearchPanel,
  Paging,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { useRecoilValue } from "recoil";

import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { updateSites } from "../../../pages/UpdateScreen/UpdateSiteInfo.types";
import { ViewExecutionLogsProps } from "./ViewExecutionLogs.types";
import { dataGridConfig, defaultPageSize } from "../../../constants";
import { convertUTCTimeToLocal, calculateGridHeight } from "../../../util/Utils";
import useSnackbar from "../../../hooks/useSnackbar";
import { SnackbarMessage } from "../../../hooks/useSnackbar.types";
import { getAppName } from "../../commonComponents/AppNameToggle";
import { SyncTypeEnum, Username } from "../../../api-client-nswag/taxportal-client";

const initialStateData = { pending: false, fulfilled: false, sites: [], totalCount: 0 };

export const ViewExecutionLogs = ({
  optionsDropdown,
  optionSelected,
  isDisplayModal,
  handleClose,
}: ViewExecutionLogsProps) => {
  const { showSnackbar } = useSnackbar();
  const [data, setData] = useState<updateSites>(initialStateData);
  const [dropDown, setDropDown] = useState<number>(optionSelected);
  const [pageNumber, setpageNumber] = useState(1);
  const appName = useRecoilValue(getAppName);

  const dropdownOptions = optionsDropdown ?? [];
  const options = dropdownOptions.map((option) => ({
    name: option.displayName,
    value: SyncTypeEnum[option.jobType as keyof typeof SyncTypeEnum],
  }));

  const fetchDetails = async (dropDown: number | null) => {
    setData({ pending: true, fulfilled: true, sites: [], totalCount: 0 });
    try {
      const startIndex = pageNumber === 1 ? 0 : (pageNumber - 1) * defaultPageSize;
      const data = await taxPortalClientApi.taxPortal_GetAllSites(
        dropDown!,
        Username.ADMIN,
        appName,
        startIndex,
        defaultPageSize
      );
      setData({ pending: false, fulfilled: true, sites: data.data, totalCount: data.totalCount });
      showSnackbar({ message: SnackbarMessage.SCHEDULE_DETAILS });
    } catch (error) {
      setData(initialStateData);
    }
  };

  const cellRenderDate = (e: string) => {
    return <div>{convertUTCTimeToLocal(e)}</div>;
  };
  const cellRenderForMessage = (e: any) => {
    if (e.data.message === null && e.data.status === null) {
      return null;
    }
    return <div title={e.data.message}>{e.data.status}</div>;
  };
  const handleDropDownChange = (e: SelectChangeEvent<number>) => {
    setDropDown(e.target.value as number);
  };

  useEffect(() => {
    setDropDown(optionSelected);
  }, [optionSelected]);

  useEffect(() => {
    if (!!dropDown) fetchDetails(dropDown);
  }, [dropDown, pageNumber]);

  return (
    <Modal
      title="Schedule Execution logs"
      maxWidth="xl"
      btnCloseText="Cancel"
      isDisplayModal={isDisplayModal}
      handleClose={handleClose}
    >
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <DataGrid
          height={calculateGridHeight(270)}
          {...dataGridConfig}
          dataSource={data.sites}
          noDataText={data.pending === true ? "Loading..." : "No data"}
        >
          <Scrolling mode="standard" />
          <LoadPanel enabled={false} />
          <SearchPanel visible />
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          {/* <Paging enabled={true} defaultPageSize={20} /> */}
          <Scrolling columnRenderingMode="virtual" />
          <Toolbar>
            <Item location="after">
              <FormControl size="medium" variant="outlined" sx={{ width: 400, marginRight: "-15px" }}>
                <Select
                  style={{ height: 36, fontSize: "14px" }}
                  defaultValue={optionSelected}
                  onChange={handleDropDownChange}
                >
                  {options.map(({ name, value }) => (
                    <MenuItem key={value} value={value} style={{ fontSize: "14px" }}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
            <Item name="searchPanel" location="after" />
          </Toolbar>
          <Column width="70px" alignment="left" dataField="hqSiteId" caption={"SiteID"} />
          <Column width="70px" alignment="left" dataField="mdmClientId" caption={"MasterID"} />
          <Column width="70px" alignment="left" dataField="mdmClientId" caption={"ClientID"} />
          <Column dataField="hqClientName" caption={"SiteName"} />
          <Column width="170px" dataField="siteCreatedBy" caption={"Site Created by"} />
          <Column
            width="190px"
            cellRender={(e) => cellRenderDate(e.data.siteCreated)}
            dataField="Site Created Date / Time"
            caption={"Site Created Date / Time"}
          />
          <Column cellRender={cellRenderForMessage} width="70px" dataField="status" caption={"Status"} />
          <Column width="170px" dataField="lastModifiedBy" caption={"Last Updated by"} />
          <Column
            width="190px"
            cellRender={(e) => cellRenderDate(e.data.lastModified)}
            dataField="Last Update Date/Time"
            caption={"Last Update Date/Time"}
          />
        </DataGrid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "5px 0 0 0",
          }}
        >
          <Pagination
            onChange={(e: any, pageNumber: any) => {
              setpageNumber(pageNumber);
            }}
            count={Math.ceil((data.totalCount as number) / defaultPageSize)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </Modal>
  );
};
